.banner-img-thumbnail-container{
  width: 114px; 
  height: 40px; 
  overflow: hidden; 
  text-align: center;
  position: relative;
}

.banner-img-thumbnail-container img {
  position: absolute;
  margin: auto;
  left: -999px;
  right: -999px;
  top: -999px;
  bottom: -999px;
  width: 100%;
}

.react-swipeable-view-container [aria-hidden=true]{
  max-height: 100px;
}