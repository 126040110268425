@import "https://fonts.googleapis.com/css?family=Kanit|Prompt";
body {
  background: #fff;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.iphone-x {
  margin-top: 20px;
}

.cover-img{
  position: absolute;
  width: 290px;
  height: 191px;
  filter: brightness(0.6);
  background-color: white;
}

.iphone-x .screen {
  width: 42%;
  height: 625px;
  margin: 0 auto;
  overflow: hidden;
  border: 10px solid #ffffff;
  min-width: 310px;
  max-width: 310px;
  background: #fafafa;
  border-radius: 44px;
  box-shadow: 0 0 20px rgb(199 195 195 / 42%);
  position: relative;
}
.cover {
  width: 100%;
  margin-top: -44px;
  background: antiquewhite;
  border-radius: 33px 33px 0px 0px;
  height: 191px;
  /* background-image: url(/assets/img/sample-default-bg-cover.jpg); */
}

.store-lable-img {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: 16px;
  background-color: white;
  top: 45px;
}
.welcome-lable {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-size: 15px;
  color: #292929;
  margin: 17px 16px 16px 12px;
}
.point-lable {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  margin: 16px 0 0 8px;
  text-align: left;
  font-size: 17px;
}
.point-text {
  font-family: "Prompt", sans-serif;
  font-size: 12px;
}
.textHeader {
  font-family: "Prompt", sans-serif;
  font-size: 15px;
  color: #2e2e2e;
}

.textHeaderFilter {
  font-family: "Prompt", sans-serif;
  font-size: 12px;
  color: #2e2e2e;
}
.filterLebelYellow {
  background: #ffc600;
  border: 1px solid #ffc600;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
  color: #2e2e2e;
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.filterLebelGrey {
  background: #ffffff;
  color: #acacac;
  border: 1px solid #ecececf2;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Prompt", sans-serif;
  font-size: 12px;
}
.flex-container > div {
  width: 81px;
  margin: 3px;
  text-align: center;
  line-height: 23px;
}
.flex-container {
  margin-left: 0x;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}
.flex-container-card {
  margin-left: 0px;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}
.tabComponent {
  margin-top: 13px;
  margin-left: -5px;
  height: 68px;
  width: 292px;
  background: #ffffff;
  border-radius: 0px 0px 38px 38px;
}
.tab-text {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-top: -6px;
  color: #292929;
}
.tab-icon-text {
  text-align: center;
  padding: 10px 0 0 0;
}

.camTextDetail {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-size: 12px;
  color: #292929;
  margin: 13px 13px 8px 12px;
}
.camTextDetail2 {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  margin: 49px 0px 34px 72px;
  font-size: 15px;
}
.camTextDetail3 {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  margin-left: 6px;
  font-size: 8px;
}
.camTextDetail4 {
  font-family: "Prompt", sans-serif;
  font-style: normal;
  margin-left: 13px;
  font-size: 8px;
  color: #b7b7b7;
}
.line {
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 97.62%;

  /* Border-Divider / Light */

  background: #ebebeb;

  /* Inside Auto Layout */
  height: 0.5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.cardCampaing {
  margin-top: 15px;
  margin-left: 9px;
  height: 219px;
  width: 260px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 3px rgb(0 0 0 / 4%);
  border-radius: 8px;
}
.campaignImg {
  width: 260px;
  border-radius: 8px 8px 0 0;
}

.store-lable-text {
  position: relative;
  width: 221px;
  left: 68px;
  top: 13px;
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  height: 30px;
  line-height: 23px;
  color: #ffffff;
  
}
.bottom {
  height: 37px;
  background: #ffc600;
  border-radius: 0px 0px 8px 8px;
}

.top-profile-bar {
  height: 54px;
}
.screen .notch {
  z-index: 20;
  width: 159px;
  height: 30px;
  margin: 0px auto;
  background: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
}

.sound {
  top: 10px;
  left: 54px;
  width: 40px;
  height: 6px;
  position: relative;
  background: #b7b7b7;
  border-radius: 10px;
}
.barcodeIcon {
  margin: 10px 0 9px 11px;
}
.showMemberIcon {
  margin: 11px 0 9px 25px;
}
.showMemberText {
  font-family: "Prompt", sans-serif;
  font-size: 13px;
}
.bottom-text-show-member-position {
  margin: 7px 0 0px -9px;
}

.point-bar {
  position: relative;
  width: 261px;
  height: 91px;
  left: 15px;
  top: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 15%);
  border-radius: 8px;
}

.time {
  color: #ffffff;
  width: 60px;
  font-size: 10px;
  z-index: 100000;
  margin-top: -26px;
  position: relative;
  background: transparent;
  margin-left: 22px;
}

.fa-battery-three-quarters {
  top: -21px;
  z-index: 100000;
  left: 240px;
  color: #ffffffbf;
  position: relative;
  background: transparent;
}

.top-nav {
  margin-top: 37px;
  margin-left: 20px;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;

  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.top-nav .fa-bell {
  color: #ddd;
  width: 33px;
  font-size: 18px;
  background: transparent;
}

.top-nav .profile {
  width: 30px;
  height: 25px;
  border: none;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1038399/profile/profile-80.jpg?1532828748");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.welcome-lable2 {
  font-size: 14px;
  color: #423c3c;
  margin: 17px 16px 16px 12px;
}

.tc-section{
  margin-top: 9vh;
}

.button-tc{

  left: 5vh;
}


.button-tv{

  left: 5vh;
}
